import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

// Path must start with a slash: /api/magic
export const getBasedUrl = (path: string) => {
  const baseUrl = process.env.SITE_URL
    ? process.env.SITE_URL
    : "http://localhost:3000";

  return baseUrl + path;
};
