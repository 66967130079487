"use client";

import { env } from "@/env";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { subscribeMailingList } from "./mailing-actions";
import { useFormState } from "react-dom";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";

export const MailingListForm = () => {
  const [state, submitAction] = useFormState(subscribeMailingList, {
    error: null,
    success: false,
  });

  return (
    <form
      className="flex flex-col gap-2 w-full max-w-[700px]"
      action={submitAction}
    >
      <div className="flex flex-col gap-2 md:gap-0 md:flex-row">
        <Input
          name="email"
          type="email"
          placeholder="Enter your email address"
          className="w-full h-12 text-md md:rounded-r-none"
          id="get-notified-input"
          data-umami-event="Get notified input"
          required
        />
        <Button
          className="btn btn-primary font-semibold text-lg h-12 md:text-xl font-heading capitalize leading-relaxed md:rounded-l-none"
          size="lg"
          id="get-notified-button"
          data-umami-event="Get notified button"
        >
          Get notified
        </Button>
      </div>

      <div
        className="cf-turnstile"
        data-sitekey={env.NEXT_PUBLIC_TURNSTILE_SITE_KEY}
      ></div>

      {state.error && (
        <p className="text-red-500 text-sm mt-2 md:mt-0">{state.error}</p>
      )}

      {state.success && <ThankYouDialog />}
    </form>
  );
};

const ThankYouDialog = () => {
  return (
    <Dialog defaultOpen>
      <DialogContent>
        <DialogHeader>
          <DialogTitle className="text-2xl text-center flex flex-col gap-2">
            <span className="text-5xl">🎉</span>
            Thank you for subscribing!
          </DialogTitle>
          <DialogDescription className="text-center">
            We'll let you know as soon as our Easy Self Host guide is ready.
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};
